import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { usePermission } from "hooks/index";

const FixedAssetAdditionActionButtons = () => {
  const { t } = useTranslation();
  const permission = usePermission("fixedAssetAddition");
  const assetClassificationPermission = usePermission("assetClassification");

  return (
    <Flex vertical gap={10} align="end">
      <Space>
        {permission.canWrite && assetClassificationPermission?.canRead && (
          <Link to="/tenant/fixed_asset_additions/new">
            <Button icon={<PlusOutlined />} type="primary">
              {t("fixed_asset_additions.layout.new")}
            </Button>
          </Link>
        )}
      </Space>
    </Flex>
  );
};

export default FixedAssetAdditionActionButtons;
