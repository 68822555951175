import axios from "axios";

import { PaymentTerms, PaymentTermPagination } from "types/PaymentTerm";
import { QueryParameters } from "types/QueryParameters";

export const getPaymentTerms = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<PaymentTerms> => {
  const { data } = await axios.get<PaymentTermPagination>(
    "/tenant/tenant_payment_terms.json",
    {
      params: {
        "q[name_cont]": search,
        "q[s]": sorter || "name asc",
        page,
        per_page: pageSize || 20,
        ...filters,
      },
    },
  );
  return data.payment_terms;
};
