import axios from "axios";
import { Depreciation } from "types/Depreciation";

export const getDepreciation = async (
  depreciationID: number,
): Promise<Depreciation> => {
  const { data } = await axios.get<Depreciation>(
    `/tenant/depreciations/${depreciationID}.json`,
  );
  return data;
};
