import { Skeleton, Table } from "antd";
import { getDepreciation } from "api/Depreciation";
import ServerError from "components/Errors/ServerError";
import { depreciationKeys } from "features/query-keys";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const ShowDepreciation = ({ depreciationID }: { depreciationID: number }) => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useQuery({
    queryKey: depreciationKeys.detail(depreciationID),
    queryFn: () => getDepreciation(depreciationID),
  });

  const columns = [
    {
      key: "fixed_asset_name",
      dataIndex: "fixed_asset_name",
      title: t("activerecord.attributes.fixed_asset.asset_name"),
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: t("activerecord.attributes.fixed_asset.registered_asset_ref"),
    },
    {
      key: "description",
      dataIndex: "description",
      title: t("activerecord.attributes.depreciation.desc"),
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: t("activerecord.attributes.depreciation.amount"),
    },
  ];

  const dataSource = data?.value_adjustments.map((value_adjustment) => {
    const registry = value_adjustment.asset_registry;

    return {
      key: value_adjustment.id,
      fixed_asset_name: registry?.fixed_asset?.locale_name,
      sku: registry?.serial_number || registry?.sku,
      description: value_adjustment.description,
      amount:
        Math.round((Number(value_adjustment.amount) + Number.EPSILON) * 100) /
        100,
    };
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <ServerError />;
  }

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};

export { ShowDepreciation };
