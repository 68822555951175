import axios from "axios";
import { Products } from "types/Product";
import { QueryParameters } from "types/QueryParameters";

export const getProducts = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<Products> => {
  const { data } = await axios.get<{ products: Products }>(
    "/tenant/products.json",
    {
      params: {
        "q[name_or_en_name_cont]": search,
        "q[s]": sorter || "created_at desc",
        page,
        per_page: pageSize || 20,
        ...filters,
      },
    },
  );
  return data.products;
};
