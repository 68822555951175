import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  FixedAssetAdditionsTable,
  FixedAssetAdditionNew,
} from "components/tenant/FixedAssetAdditions";
import NoMatch from "components/layout/NoMatch";
import { useQuery } from "react-query";
import { currentUserKeys } from "features/query-keys";
import { getCurrentUser } from "api/CurrentUser";
import { Skeleton } from "antd";
import UserContext from "contexts/UserContext";
import { FixedAssetAdditionEdit } from "components/tenant/FixedAssetAdditions/FixedAssetAdditionEdit";
import { usePermission } from "hooks/index";

const FixedAssetAdditions = () => {
  const { data, isLoading } = useQuery({
    queryKey: currentUserKeys.all,
    queryFn: getCurrentUser,
  });
  const assetClassificationPermission = usePermission("assetClassification");
  const billPermission = usePermission("bill");

  if (isLoading) return <Skeleton />;

  return (
    <UserContext.Provider value={data}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/tenant/fixed_asset_additions"
            element={<FixedAssetAdditionsTable />}
          />
          {assetClassificationPermission?.canRead && (
            <>
              <Route
                path="/tenant/fixed_asset_additions/new"
                element={<FixedAssetAdditionNew />}
              />
              <Route
                path="/tenant/fixed_asset_additions/:additionID/edit"
                element={<FixedAssetAdditionEdit />}
              />
              <Route
                path="/tenant/fixed_asset_additions/:additionID"
                element={<FixedAssetAdditionEdit disabled />}
              />
              <Route
                path="/tenant/fixed_asset_additions/new/merge"
                element={<FixedAssetAdditionNew.Merge />}
              />

              {billPermission?.canRead && (
                <Route
                  path="/tenant/fixed_asset_additions/new/link_bill"
                  element={<FixedAssetAdditionNew.LinkBill />}
                />
              )}
              {billPermission?.canApprove && (
                <Route
                  path="/tenant/fixed_asset_additions/new/create_bill"
                  element={<FixedAssetAdditionNew.CreateBill />}
                />
              )}
            </>
          )}

          <Route path="/tenant/fixed_asset_additions/*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default FixedAssetAdditions;
