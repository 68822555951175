import {
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import dayjs, { type Dayjs } from "dayjs";

import { getVendors } from "api/Contact";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { Contact } from "types/Contact";
import { getPaymentTerms } from "api/PaymentTerm";
import { PaymentTerm } from "types/PaymentTerm";
import { getInventories } from "api/Inventory";
import { Inventory } from "types/Inventory";
import i18next from "i18next";
import LineItem from "./LineItems";
import TotalEntry from "./TotalEntry";
import { Bill } from "types/CommercialDocument";
import {
  contactVendorKeys,
  inventoryKeys,
  tenantPaymentTermsKeys,
} from "features/query-keys";
import { useAddition } from "../../hooks";
import { useQuery } from "react-query";
import { initializeBill } from "api/Bill";

const BillDetails = ({ record }: { record?: Bill }) => {
  const { t } = useTranslation();
  const additionInstance = useAddition();
  const form = additionInstance.form;
  const billItems =
    (record?.bill_items && record.bill_items) ||
    (record?.bill_items_attributes &&
      Object.values(record.bill_items_attributes));
  const billDate = additionInstance.additionDateWatch || dayjs();

  React.useEffect(() => {
    const issueDate = form.getFieldValue(["bill", "issue_date"]) as
      | Dayjs
      | undefined;
    if (issueDate?.isAfter(billDate)) {
      form.setFieldsValue({
        bill: {
          issue_date: billDate,
          due_date: billDate,
          supply_date: billDate,
        },
      });
    }
  }, [billDate]);

  const { data, isLoading } = useQuery({
    queryKey: ["bills", "initialize"],
    queryFn: initializeBill,
    cacheTime: 0,
  });

  const { componentDisabled } = ConfigProvider.useConfig();

  if (isLoading) return <Skeleton />;

  let vendorFilters = {};
  if (componentDisabled) {
    vendorFilters = {
      id_eq: record?.contact_id,
    };
  }

  return (
    <React.Fragment key="bill-details-form">
      <Row key="bill-details">
        <Col md={12} xs={24} key="bill-details-0">
          <Form.Item
            name={["bill", "reference"]}
            label={t("activerecord.external_documents.bill.reference")}
            initialValue={record?.reference || data?.reference}
            extra={
              !(record?.id && record?.reference) &&
              data.numbering == "locked" && (
                <span>
                  <Typography.Text>
                    {t("activerecord.external_documents.bill.auto_no_text")}
                  </Typography.Text>
                </span>
              )
            }
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              disabled={data?.numbering == "locked" || componentDisabled}
            />
          </Form.Item>
          <Form.Item
            name={["bill", "contact_id"]}
            label={t("activerecord.external_documents.bill.customer")}
            initialValue={Number(record?.contact_id) || null}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              queryKeyObject={contactVendorKeys}
              queryFn={getVendors}
              filters={vendorFilters}
              dataLabelFn={(contact: Contact) => contact.name}
            />
          </Form.Item>
          <Form.Item
            name={["bill", "issue_date"]}
            label={t("activerecord.external_documents.bill.issue_date")}
            initialValue={
              (record?.issue_date && dayjs(record.issue_date)) || billDate
            }
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  }
                  if (billDate && value.isAfter(billDate))
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                const date = additionInstance.additionDateWatch || dayjs();

                return current && current > date;
              }}
            />
          </Form.Item>
          <Form.Item
            name={["bill", "payment_term_id"]}
            label={t("activerecord.attributes.invoice.payment_terms")}
          >
            <SelectWithSearch
              queryKeyObject={tenantPaymentTermsKeys}
              queryFn={getPaymentTerms}
              onChange={(_value: number, option: PaymentTerm) => {
                const numberOfDays = option?.days_after || 0;
                const currentIssueDate = dayjs(
                  form.getFieldValue(["bill", "issue_date"]) as
                    | Dayjs
                    | undefined,
                );

                form.setFieldValue(
                  ["bill", "due_date"],
                  currentIssueDate.add(numberOfDays, "days"),
                );
              }}
              dataLabelFn={(term: PaymentTerm) => term.payment_term}
            />
          </Form.Item>
          <Form.Item
            name={["bill", "inventory_id"]}
            label={t("forms.placeholders.select_inventory")}
            initialValue={Number(record?.inventory_id) || null}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={record?.inventory ? [record?.inventory] : []}
              queryKeyObject={inventoryKeys}
              queryFn={getInventories}
              dataLabelFn={(inventory: Inventory) =>
                i18next.language === "en" ? inventory.name : inventory.ar_name
              }
              selectFirstOption
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24} key="bill-details-1">
          <Form.Item
            name={["bill", "description"]}
            label={t("activerecord.external_documents.bill.description")}
            initialValue={record?.description}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["bill", "due_date"]}
            label={t("activerecord.external_documents.bill.due_date")}
            initialValue={
              (record?.due_date && dayjs(record.due_date)) || billDate
            }
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  }
                  const issueDate = form.getFieldValue([
                    "bill",
                    "issue_date",
                  ]) as Dayjs | undefined;
                  if (issueDate && value?.isBefore(issueDate))
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                const endDay =
                  (form.getFieldValue(["bill", "issue_date"]) as
                    | Dayjs
                    | undefined) || dayjs().endOf("day");
                return current && current < endDay;
              }}
            />
          </Form.Item>
          <Form.Item
            name={["bill", "supply_date"]}
            label={t("activerecord.external_documents.bill.supply_date")}
            initialValue={
              (record?.supply_date && dayjs(record.supply_date)) || billDate
            }
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value: Dayjs) => {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  }

                  const issueDate = form.getFieldValue([
                    "bill",
                    "issue_date",
                  ]) as Dayjs | undefined;
                  if (issueDate && value?.isBefore(issueDate))
                    return Promise.reject(
                      new Error(
                        t("forms.messages.journal.date_format_validation"),
                      ),
                    );
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                const endDay =
                  (form.getFieldValue(["bill", "issue_date"]) as
                    | Dayjs
                    | undefined) || dayjs().endOf("day");
                return current && current < endDay;
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <LineItem key="line-items" records={billItems} />
      <TotalEntry />
    </React.Fragment>
  );
};

export default BillDetails;
