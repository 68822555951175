import axios from "axios";
import { QueryParameters } from "types/QueryParameters";
import { Taxes } from "types/Taxes";

export const getTaxes = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<Taxes> => {
  const { data } = await axios.get<{ taxes: Taxes }>("/tenant/taxes.json", {
    params: {
      "q[arabic_name_or_english_name_cont]": search,
      "q[s]": sorter || "created_at desc",
      page,
      per_page: pageSize || 20,
      ...filters,
    },
  });
  return data.taxes;
};
