import React from "react";
import { Form, Input } from "antd";

import "../styles.css";
import { AssetRegistry } from "types/AssetRegistry";
import { UseMutationResult } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import { BillSelector } from "./BillSelector";
import RecordContext from "contexts/RecordContext";
import { DepreciationDetails } from "./DepreciationDetails";
import { AssetRegistryList } from "./AssetRegistryList";
import GeneralForm from "../GeneralForm";

export const FixedAssetAdditionLinkBillForm = ({
  mutation,
}: {
  mutation: UseMutationResult<FixedAssetAddition>;
}) => {
  const record = React.useContext(RecordContext) as FixedAssetAddition;

  const transaction = record?.fixed_asset_addition_transactions?.find(
    (tr) =>
      tr.transactionable_type === "Bill" ||
      tr.transactionable_type === "SimpleBill",
  );

  const showMergeRegistries = (mainRegistry: AssetRegistry) => {
    return <AssetRegistryList mainRegistry={mainRegistry} />;
  };

  const calculateDepreciationComponent = (mainRegistry: AssetRegistry) => {
    return <DepreciationDetails mainRegistry={mainRegistry} />;
  };

  return (
    <GeneralForm
      record={record}
      mutation={mutation}
      renderListRegistryComponent={showMergeRegistries}
      renderCalculateDepreciationComponent={calculateDepreciationComponent}
      additionType="link_bill"
    >
      <Form.Item
        hidden
        name={["fixed_asset_addition", "addition_type"]}
        initialValue="link_bill"
      >
        <Input type="hidden" />
      </Form.Item>
      <BillSelector initialData={transaction} />
    </GeneralForm>
  );
};
