import { useTranslation } from "react-i18next";

interface Setting {
  currency: string | null;
  date_format: string | null;
  fiscal_year_start?: string | null;
}

export const useSettings = (): Setting => {
  const { t } = useTranslation();
  const defaultSettings = {
    currency: t("activerecord.attributes.subscription.sar"),
    date_format: "YYYY-mm-dd",
  };

  return (window.Settings || defaultSettings) as Setting;
};
