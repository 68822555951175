import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Form,
  Input,
  ConfigProvider,
} from "antd";
import React, { useState } from "react";
import BillDetails from "./BillDetails";
import { Bill } from "types/CommercialDocument";
import { useTranslation } from "react-i18next";

const BillForm = ({ record }: { record?: Bill }) => {
  const { t } = useTranslation();
  const [showFormModal, setShowFormModal] = useState(false);
  const form = Form.useFormInstance();

  const handleCancel = () => {
    setShowFormModal(false);
  };

  const handleOk = () => {
    form
      .validateFields(["bill"], { recursive: true })
      .then(() => {
        form
          .validateFields(["discount"], { recursive: true })
          .then(() => {
            setShowFormModal(false);
            form.setFieldValue("bill_created", "bill_created");
          })
          .catch((errorInfo) => {
            console.log("errorInfo", errorInfo);
          });
      })
      .catch((errorInfo) => {
        console.log("errorInfo", errorInfo);
      });
  };

  const { componentDisabled } = ConfigProvider.useConfig();

  return (
    <Row>
      <Col span={12} offset={8}>
        <Button
          type="primary"
          onClick={() => setShowFormModal(true)}
          disabled={false}
        >
          {t("templates.head.bill")}
        </Button>
      </Col>
      <Form.Item hidden name="bill_created" rules={[{ required: true }]}>
        <Input hidden />
      </Form.Item>
      <Form.Item
        hidden
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_id",
        ]}
        initialValue={record?.id || 0}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        hidden
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_type",
        ]}
        initialValue={"Bill"}
      >
        <Input type="hidden" />
      </Form.Item>
      <Modal
        width="80%"
        title={t("templates.head.bill")}
        closable
        open={showFormModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className={componentDisabled ? "show-page" : ""}
      >
        <Card>
          <BillDetails record={record} />
        </Card>
      </Modal>
    </Row>
  );
};

export default BillForm;
