import axios from "axios";
import { User } from "types/User";

/* eslint-disable */
export const getCurrentUser = async (): Promise<User> => {
  const { data } = await axios.get("/tenant/current_user.json");

  return data;
};
/* eslint-enable */
