import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  Modal,
  Row,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AssetRegistries } from "types/AssetRegistry";
import { CalculationDepreciationEntry } from "./CalculationDepreciationEntry";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import { ShowDepreciation } from "./ShowDepreciation";

export const CalculateDeprecation = ({
  registries,
  depreciationID,
}: {
  registries: AssetRegistries;
  depreciationID?: number;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [activeTab, setActiveTab] = useState("depreciation-entries");
  const form = Form.useFormInstance();
  const componentDisabled = React.useContext(DisabledContext);

  useEffect(() => {
    if (form.getFieldValue("depreciation_calculated") === "recalculate") {
      setIsCalculated(false);
    }
  }, [form.getFieldValue("depreciation_calculated")]);

  const calculateDepreciation = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    form
      .validateFields(["depreciation"], { recursive: true })
      .then(() => {
        setIsModalOpen(false);
        setIsCalculated(true);
        form.setFieldValue("depreciation_calculated", "calculated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const depreciated = (
    <Card className="bg-gray-light" style={{ width: "70%", float: "right" }}>
      <span>{t("fixed_asset_additions.layout.depreciation_calculated")}</span>
    </Card>
  );

  const onTabChange = (active: string) => {
    setActiveTab(active);
  };

  const depreciatedRegisteries = registries.filter(
    (register) =>
      Number(register.unbooked_amount) !== 0 &&
      (register.fixed_asset.depreciated ?? true),
  );
  const screens = Grid.useBreakpoint();

  if (componentDisabled && depreciationID) {
    return (
      <>
        <Row>
          <Col
            span={24}
            style={{
              color: "#25233a",
              fontWeight: 700,
            }}
          >
            <Divider
              className="form-divider"
              orientation="left"
              orientationMargin="0"
            >
              {t("fixed_asset_additions.layout.depreciation")}
            </Divider>
          </Col>
        </Row>
        <Row gutter={[0, 8]}>
          <Col
            sm={18}
            xs={24}
            offset={screens.xs ? 0 : 4}
            style={{ marginTop: 20 }}
          >
            <ShowDepreciation depreciationID={depreciationID} />
          </Col>
        </Row>
      </>
    );
  }

  const isDepreciated = depreciatedRegisteries.length === 0 || isCalculated;

  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t("fixed_asset_additions.layout.depreciation")}
          </Divider>
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Col span={14}>
          <Card
            hidden={isDepreciated}
            className="bg-gray-light"
            style={{ width: "70%", float: "right" }}
          >
            <Form.Item
              hidden
              name="depreciation_calculated"
              initialValue={isDepreciated && "calculated"}
              rules={[
                {
                  validator: (_rule, value) => {
                    if (value === "calculated" || isDepreciated)
                      return Promise.resolve();

                    return Promise.reject(
                      new Error("Depreciation should be calculated"),
                    );
                  },
                },
              ]}
            >
              <Input type="hidden" />
            </Form.Item>
            <span>{t("fixed_asset_additions.layout.depreciation_text")}</span>

            <Button
              type="primary"
              onClick={calculateDepreciation}
              style={{
                float: i18next.language === "en" ? "right" : "left",
              }}
            >
              {t("forms.buttons.calculate")}
            </Button>
            <Modal
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              width="50%"
            >
              <Tabs
                defaultActiveKey="1"
                type="card"
                size="middle"
                onChange={onTabChange}
                activeKey={activeTab}
                items={[
                  {
                    label: t(
                      "activerecord.assets.classification.depreciation_type.posting_depreciation",
                    ),
                    key: "depreciation-entries",
                    children: activeTab === "depreciation-entries" && (
                      <CalculationDepreciationEntry
                        registeries={depreciatedRegisteries}
                      />
                    ),
                  },
                  {
                    label: t(
                      "activerecord.assets.classification.depreciation_type.manual_depreciation",
                    ),
                    key: "manual-depreciation",
                    children: activeTab === "manual-depreciation" && (
                      <CalculationDepreciationEntry
                        registeries={depreciatedRegisteries}
                        isEditable
                      />
                    ),
                  },
                ]}
              />
            </Modal>
          </Card>
          {isDepreciated && depreciated}
        </Col>
      </Row>
    </>
  );
};
