import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Skeleton, Typography } from "antd";
import dayjs from "dayjs";

import "../styles.css";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { getAssetRegistries, getAssetRegistry } from "api/AssetRegistry";
import { AssetRegistry } from "types/AssetRegistry";
import { Input } from "antd/lib";
import { CalculateDeprecation } from "../CalculateDepreciation";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { UseMutationResult, useQuery } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import { assetRegistryKeys } from "features/query-keys";
import RecordContext from "contexts/RecordContext";
import GeneralForm from "../GeneralForm";
import { useAddition } from "../hooks";

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
export const FixedAssetAdditionMergeForm = ({
  mutation,
}: {
  mutation: UseMutationResult<FixedAssetAddition>;
}) => {
  const { t } = useTranslation();
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const depreciationTransaction =
    record?.fixed_asset_addition_transactions?.find(
      (tr) => tr.transactionable_type === "Depreciation",
    );
  const transaction = record?.fixed_asset_addition_transactions?.find(
    (tr) => tr.transactionable_type === "AssetRegistry",
  );

  const addition = useAddition();

  const mergeRegistryID = Form.useWatch(
    [
      "fixed_asset_addition",
      "fixed_asset_addition_transactions_attributes",
      0,
      "transactionable_id",
    ],
    addition.form,
  );

  const merge = useQuery({
    queryKey: assetRegistryKeys.details(
      mergeRegistryID,
      addition.additionDateWatch &&
        dayjs(addition.additionDateWatch).format("YYYY-MM-DD"),
    ),
    queryFn: () => {
      if (!mergeRegistryID) return;

      const dateString =
        addition.additionDateWatch &&
        dayjs(addition.additionDateWatch).format("YYYY-MM-DD");

      return getAssetRegistry(mergeRegistryID, {
        depreciation_date: dateString,
      });
    },
  });

  useEffect(() => {
    if (!mergeRegistryID) {
      return;
    }

    merge.refetch().catch(() => {
      console.log("Fetch failed");
    });
  }, [addition.additionDateWatch, mergeRegistryID]);

  const mergeRegistryFilter = {
    q: {
      id_not_eq: addition.mainAssetRegistry.mainAssetRegistry,
      g: [
        {
          start_depreciation_date_and_received_date_lteq:
            record.date || dayjs().format("YYYY-MM-DD"),
          start_depreciation_date_null: true,
          m: "or",
        },
      ],
    },
  };

  if (addition.additionDateWatch) {
    mergeRegistryFilter.q.g[0].start_depreciation_date_and_received_date_lteq =
      addition.additionDateWatch.format("YYYY-MM-DD");
  }

  useEffect(() => {
    if (!addition.additionDateWatch && mergeRegistryID) {
      addition.form.setFieldValue(
        [
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_id",
        ],
        null,
      );
    }
  }, [addition.additionDateWatch]);

  const showMergeRegistry = () => {
    return merge.isLoading ? (
      <Skeleton />
    ) : (
      merge.data?.id && (
        <AssetRegistryShow
          assetRegistry={merge.data}
          title={t("fixed_asset_additions.layout.merge_asset")}
        />
      )
    );
  };

  const calculateDepreciationComponent = (mainRegistry: AssetRegistry) => {
    return merge.isLoading ? (
      <Skeleton />
    ) : (
      merge.data && (
        <CalculateDeprecation
          registries={[mainRegistry, merge.data]}
          depreciationID={depreciationTransaction?.transactionable_id}
        />
      )
    );
  };

  return (
    <GeneralForm
      record={record}
      mutation={mutation}
      renderListRegistryComponent={showMergeRegistry}
      renderCalculateDepreciationComponent={calculateDepreciationComponent}
      additionHook={addition}
      additionType="asset_merge"
    >
      <Form.Item
        hidden
        name={["fixed_asset_addition", "addition_type"]}
        initialValue="asset_merge"
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_type",
        ]}
        initialValue="AssetRegistry"
        hidden
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        initialValue={transaction?.transactionable_id}
        label={t("activerecord.attributes.fixed_asset_addition.asset_to_merge")}
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_id",
        ]}
        key="asset-registry-merge"
        extra={
          <span>
            <Typography.Text>
              {t(
                "fixed_asset_additions.layout.registered_asset_to_merge.description",
              )}
            </Typography.Text>
          </span>
        }
        rules={[
          {
            required: true,
          },
        ]}
      >
        <SelectWithSearch
          initialOpts={[transaction?.transactionable]}
          queryKeyObject={assetRegistryKeys}
          onChange={addition.mergeRegistry.handleChange}
          filters={mergeRegistryFilter}
          queryFn={getAssetRegistries}
          dataLabelFn={(registry: AssetRegistry) => registry.sku}
        />
      </Form.Item>
    </GeneralForm>
  );
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
