import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NoMatch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="error-main-block">
      <div className="col-md-12">
        <div className="error-block clearfix">
          <div className="col-md-6 col-md-offset-3">
            <div className="error-block-content">
              <div className="error400">
                404
                <sub>
                  <br />
                  {t("activerecord.attributes.home.bad_request")}
                </sub>
              </div>
              <p>{t("activerecord.attributes.home.url")}</p>
              <div className="error-btn-group">
                <div className="back-btn pull-right">
                  <a onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left client_primary_color fw-700"></i>
                    {t("forms.buttons.back")}
                  </a>
                </div>

                <a className="btn back-home" href="/tenant/dashboards">
                  <i className="fa fa-home"></i>
                  {t("activerecord.attributes.home.home")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
