import axios from "axios";
import { FixedAsset, FixedAssets } from "types/FixedAsset";
import { QueryParameters } from "types/QueryParameters";
import { FixedAssetResponse } from "types/FixedAsset";

export const getFixedAssets = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<FixedAssets> => {
  const { data } = await axios.get<FixedAssetResponse>(
    `/tenant/fixed_assets.json`,
    {
      params: {
        "q[arabic_name_or_english_name_cont]": search,
        "q[s]": sorter || "created_at desc",
        ...(filters || {}),
        page: page,
        per_page: pageSize,
      },
    },
  );
  return data?.fixed_assets;
};

export const getFixedAsset = async (
  fixedAssetID: number | string,
): Promise<FixedAsset> => {
  const { data } = await axios.get<FixedAsset>(
    `/tenant/fixed_assets/${fixedAssetID}.json`,
  );

  return data;
};
