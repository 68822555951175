import { useSettings } from "hooks/Settings";
import i18next from "i18next";

export const formatCurrency = (value: number) => {
  const settings = useSettings();

  const numberValue = roundNumber(Number(value) || 0);

  let currencyValue = `${Math.abs(numberValue)} ${settings.currency}`;
  if (i18next.language === "en")
    currencyValue = `${settings.currency} ${Math.abs(numberValue)}`;

  if (numberValue < 0) return `(${currencyValue})`;

  return currencyValue;
};

export const roundNumber = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};
