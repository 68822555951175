import { FormInstance } from "antd";

export const handleClassificationChange = (form: FormInstance) => {
  form.setFieldValue("asset_name", "");
  form.setFieldValue(["fixed_asset_addition", "asset_registry_id"], "");
};

export const handleAssetChange = (form: FormInstance) => {
  form.setFieldValue(["fixed_asset_addition", "asset_registry_id"], "");
};
