//@ts-nocheck
import React, { useLayoutEffect, useState } from "react";
import { ConfigProvider } from "antd";
import lightToken from "themes/light.json";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import i18next from "i18next";
import Global from "types/modules/global";
import enUS from "antd/locale/en_US";
import arEG from "antd/locale/ar_EG";
import { localeMapValue } from "types/layout";
import { Locale } from "antd/lib/locale";
import dayjs from "dayjs";
import "dayjs/locale/ar-sa";

dayjs.locale("ar-sa");

const { components, ...globalTokens } = lightToken;

const arLocaleWithShortDays: Locale = {
  ...arEG,
  DatePicker: {
    ...arEG.DatePicker,
    lang: {
      ...arEG.DatePicker?.lang,
      shortWeekDays: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
    },
  },
};

i18next.use(initReactI18next).init({
  resources: {
    [window.I18n.locale]: {
      translation: window.I18n,
    },
  },
  lng: window.I18n.locale,
});

const localeMap = new Map<string, localeMapValue>([
  ["en", { locale: enUS, dir: "ltr", fontFamily: globalTokens.fontFamily }],
  ["ar", { locale: arLocaleWithShortDays, dir: "rtl", fontFamily: "Cairo" }],
]);

globalTokens.fontFamily = localeMap.get(i18next.language)?.fontFamily;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const antdConfigProps = {
  theme: { components, token: globalTokens },
  locale: localeMap.get(i18next.language)?.locale,
  direction: localeMap.get(i18next.language)?.dir,
};

interface AppProps {
  modulePath: string;
  pagePath: string;
}

const App: React.FC<AppProps> = ({ modulePath, pagePath, ...props }) => {
  const [Page, setPage] = useState(null);
  useLayoutEffect(() => {
    import(`pages/${modulePath}/${pagePath}`)
      .then((Module) => {
        setPage(<Module.default {...props} />);
      })
      .catch(() => {
        throw new Error(`Couldn't find the page`);
      });
  }, []);
  return (
    <I18nextProvider i18n={i18next}>
      <ConfigProvider {...antdConfigProps}>
        <QueryClientProvider client={queryClient}>{Page}</QueryClientProvider>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default App;
