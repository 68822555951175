import React, { useState } from "react";
import {
  Table,
  Tag,
  Button,
  Flex,
  Modal,
  Tooltip,
  TablePaginationConfig,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NoBudgetData from "assets/icons/NoBudgetData.svg";

import {
  deleteFixedAssetAddition,
  getFixedAssetAdditions,
} from "api/FixedAssetAdditions";
import QEmptyIndex from "components/layout/emptyIndex";
import QPageLayout from "components/layout/QPageLayout";
import { type FixedAssetAddition } from "types/FixedAssetAddition";

import FixedAssetAdditionFilterForm from "./FixedAssetAdditionFilterForm";
import FixedAssetAdditionActionButtons from "./FixedAssetAdditionActionButtons";

import { fixedAssetAdditionKeys } from "features/query-keys";
import ServerError from "components/Errors/ServerError";
import { SorterResult } from "antd/es/table/interface";
import { usePermission } from "hooks/index";

const tagColor = (status: string): string => {
  if (status === "draft") {
    return "orange-inverse";
  } else if (status === "awaiting") {
    return "blue-inverse";
  } else {
    return "green-inverse";
  }
};

const FixedAssetAdditionsTable = () => {
  const { t } = useTranslation();
  const permission = usePermission("fixedAssetAddition");

  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sorter, setSorter] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);

  const { data, isLoading, isError, refetch } = useQuery<
    {
      fixed_asset_additions: FixedAssetAddition[];
      pagination: { total_entries: number };
    },
    Error
  >({
    queryKey: fixedAssetAdditionKeys.paginate(
      filters,
      currentPage,
      pageSize,
      sorter,
    ),
    queryFn: () =>
      getFixedAssetAdditions(currentPage, filters, pageSize, sorter),
  });

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.title"),
    },
  ];

  if (isError) {
    return (
      <QPageLayout
        title={t("fixed_asset_additions.layout.title")}
        actionBtns={<FixedAssetAdditionActionButtons />}
        breadCrumbData={breadCrumbData}
      >
        <ServerError />
      </QPageLayout>
    );
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _data: any,
    sorter: SorterResult<FixedAssetAddition>,
  ) => {
    if (sorter.columnKey) {
      setSorter(
        `${sorter.columnKey} ${sorter.order === "descend" ? "desc" : "asc"}`,
      );
    }

    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleFilterSubmit = (values: unknown) => {
    setCurrentPage(1);
    setFilters(values);
    setFilterApplied(true);
  };

  const handleReset = () => {
    setFilters({});
    setCurrentPage(1);
    setPageSize(15);
    setSorter("");
    setFilterApplied(false);
  };

  const handleDelete = (record: FixedAssetAddition) => {
    if (!record.is_deletable) {
      Modal.error({
        title: t("fixed_asset_additions.layout.form.delete_restricted"),
        content: t(
          "fixed_asset_additions.layout.form.delete_restricted_message",
        ),
        closable: true,
        footer: null,
      });

      return;
    }

    Modal.confirm({
      content: t("templates.sure_modal"),
      okText: t("forms.buttons.delete"),
      onOk: () => {
        deleteFixedAssetAddition(record)
          .then(() => {
            refetch().catch(() => {
              console.log("Error fetching");
            });
          })
          .catch(() => {
            console.log("Error fetching");
          });
      },
    });
  };

  const columns = [
    {
      title: t("activerecord.attributes.fixed_asset_addition.reference"),
      dataIndex: "reference",
      key: "reference",
      sorter: true,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.date"),
      dataIndex: "date",
      key: "date",
      sorter: true,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.asset_name"),
      dataIndex: "fixed_asset_name",
      key: "fixed_asset_name",
      sorter: false,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.asset_reference"),
      dataIndex: "asset_registry_sku",
      key: "asset_registry_sku",
      sorter: false,
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.addition_type"),
      dataIndex: "addition_type",
      key: "addition_type",
      sorter: false,
      render: (text: string | null) => {
        if (text === null) {
          return null;
        }

        return t(
          `activerecord.attributes.fixed_asset_addition.addition_types.${text}`,
        );
      },
    },
    {
      title: t("activerecord.attributes.fixed_asset_addition.status"),
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: (text: string) => {
        if (text === null) {
          return null;
        }

        return (
          <Tag color={tagColor(text)}>
            {t(`activerecord.attributes.fixed_asset_addition.statuses.${text}`)}
          </Tag>
        );
      },
    },
    {
      title: t("activerecord.attributes.fixed_asset.action"),
      dataIndex: "action",
      render: (_: any, record: FixedAssetAddition) => {
        return (
          <Flex wrap gap="small">
            <Tooltip placement="bottom" title={t("tooltip.view")}>
              <Link to={`${record.id}`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            {permission?.canWrite &&
              (record.status === "draft" || record.status === "awaiting") && (
                <Tooltip placement="bottom" title={t("tooltip.edit")}>
                  <Link to={`${record.id}/edit`}>
                    <Button icon={<EditOutlined />} />
                  </Link>
                </Tooltip>
              )}
            {permission?.canRemove && (
              <Tooltip placement="bottom" title={t("tooltip.delete")}>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  ghost={!(record.is_deletable ?? true)}
                  onClick={() => handleDelete(record)}
                  type={record.is_deletable ? "default" : "dashed"}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<FixedAssetAdditionActionButtons />}
      breadCrumbData={breadCrumbData}
    >
      {!filterApplied &&
      !isLoading &&
      permission?.canRead &&
      data?.fixed_asset_additions?.length === 0 ? (
        <QEmptyIndex
          title={t("dashboard.information.fixed_asset_additions.title")}
          subTitle={t(
            "dashboard.information.fixed_asset_additions.description",
          )}
          Icon={NoBudgetData}
        >
          <></>
        </QEmptyIndex>
      ) : (
        <>
          <FixedAssetAdditionFilterForm
            onSubmit={handleFilterSubmit}
            onReset={handleReset}
          />
          <Table
            columns={columns}
            dataSource={data?.fixed_asset_additions}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: data?.pagination.total_entries,
              showSizeChanger: true,
              pageSizeOptions: ["5", "15", "50", "100"],
            }}
            loading={isLoading}
            onChange={handleTableChange}
            rowKey="id"
            locale={{
              emptyText: t(
                "activerecord.attributes.balance_sheet.no_data_found",
              ),
            }}
          />
        </>
      )}
    </QPageLayout>
  );
};

export { FixedAssetAdditionsTable };
