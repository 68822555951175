import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { Form, Skeleton } from "antd";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "features/query-keys";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { getAssetRegistries } from "api/AssetRegistry";
import i18next from "i18next";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import dayjs, { Dayjs } from "dayjs";

interface BillWatch {
  transactionable_id: number;
  transactionable_type: string;
}

export const AssetRegistryList = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billWatch = Form.useWatch<BillWatch>(
    ["fixed_asset_addition", "fixed_asset_addition_transactions_attributes", 0],
    form,
  );
  const additionDateWatch = Form.useWatch<Dayjs | undefined>(
    ["fixed_asset_addition", "date"],
    form,
  );
  const componentDisabled = React.useContext(DisabledContext);

  const registries = React.useRef([]);

  const filters = {
    "q[commercial_document_id_eq]": billWatch?.transactionable_id,
    "q[commercial_document_type_eq]": "Bill",
    depreciation_date: additionDateWatch
      ? dayjs(additionDateWatch).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD"),
    show_all: componentDisabled,
  };
  const registryQuery = useQuery({
    queryKey: assetRegistryKeys.list(filters),
    queryFn: () => {
      if (
        !billWatch?.transactionable_id ||
        billWatch?.transactionable_type !== "Bill"
      )
        return;

      return getAssetRegistries({ filters: filters });
    },
  });

  if (registryQuery.data) {
    registries.current = registryQuery.data.filter(
      (registry) => registry.id !== mainRegistry.id,
    );
  } else {
    registries.current = [];
  }

  React.useEffect(() => {
    if (!billWatch?.transactionable_id) return;

    registryQuery.refetch().catch((err) => {
      console.log(err);
    });
  }, [form, billWatch]);

  if (registryQuery.isLoading) return <Skeleton />;

  return (
    <div style={{ maxHeight: 400, overflowY: "scroll" }}>
      {registries.current.map((registry: AssetRegistry) => (
        <AssetRegistryShow
          assetRegistry={registry}
          title={`${
            i18next.language === "en"
              ? registry.fixed_asset?.en_name
              : registry.fixed_asset?.name
          } - ${registry.sku}`}
          key={`${registry.id}-${registry.sku}`}
        />
      ))}
    </div>
  );
};
