import { Col, Divider, Form, Grid, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { formatCurrency } from "helpers/format-currency";
import { type Bill, LineItem } from "types/CommercialDocument";

const TotalEntry = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [totalVals, setTotalVals] = React.useState({
    totalBeforeVat: 0.0,
    vatValue: 0.0,
    totalAmount: 0.0,
  });
  const formVals = Form.useWatch([], form) as { bill: Bill };
  const screens = Grid.useBreakpoint();

  const styleProps = {
    style: { textAlign: screens.xs ? "unset" : "right" } as React.CSSProperties,
  };
  const colProps = {
    sm: 12,
    xs: 24,
  };

  React.useEffect(() => {
    if (!formVals?.bill?.bill_items_attributes?.length) return;

    const newTotals = {
      totalBeforeVat: 0.0,
      vatValue: 0.0,
      totalAmount: 0.0,
    };
    formVals.bill.bill_items_attributes.forEach((item: LineItem) => {
      newTotals.totalBeforeVat += Number(item.total_before_vat) || 0.0;
      newTotals.vatValue += Number(item.vat_value) || 0.0;
      newTotals.totalAmount += Number(item.amount) || 0.0;
    });
    setTotalVals(newTotals);
  }, [form, formVals]);

  return (
    <Row>
      <Col sm={8} xs={24} offset={screens.xs ? 0 : 16}>
        <Divider />
        <Row justify="end">
          <Col {...colProps}>
            {t("activerecord.attributes.credit_note.total_before_vat")}
          </Col>
          <Col {...colProps} {...styleProps}>
            {formatCurrency(totalVals.totalBeforeVat)}
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col {...colProps}>
            {t("activerecord.attributes.credit_note.vat_value")}
          </Col>
          <Col {...colProps} {...styleProps}>
            {formatCurrency(totalVals.vatValue)}
          </Col>
        </Row>
        <Divider />
        <Row justify="end">
          <Col {...colProps}>
            {t("activerecord.attributes.credit_note.amount")}
          </Col>
          <Col {...colProps} {...styleProps}>
            {formatCurrency(totalVals.totalAmount)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TotalEntry;
