import { useQuery } from "react-query";
import { Permission } from "types/Permission";

export const usePermission = (moduleName: string) => {
  const { data } = useQuery<Permission, Error>(
    ["permissions", moduleName],
    async () => {
      const response = await fetch(`/tenant/permissions/${moduleName}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json() as Promise<Permission>;
    },
  );

  if (data) return data;
  else
    return {
      canRead: false,
      canWrite: false,
      canRemove: false,
      canApprove: false,
    };
};
