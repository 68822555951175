import axios from "axios";

import { Contacts } from "types/Contact";
import { QueryParameters } from "types/QueryParameters";

export const getVendors = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: QueryParameters): Promise<Contacts> => {
  const { data } = await axios.get<{ contacts: Contacts }>(
    "/tenant/contacts/vendor_index.json",
    {
      params: {
        "q[name_cont]": search,
        "q[s]": sorter || "name asc",
        page,
        per_page: pageSize || 20,
        ...filters,
      },
    },
  );
  return data.contacts;
};
