import { QueryKeyType } from "types/query-keys";

export const fixedAssetAdditionKeys: QueryKeyType = {
  all: ["fixed-asset-additions"],
  lists: () => [...fixedAssetAdditionKeys.all, "lists"],
  list: (filters: any) => [...fixedAssetAdditionKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...fixedAssetAdditionKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [
    ...fixedAssetAdditionKeys.all,
    "details",
    id,
  ],
};

export const assetRegistryKeys: QueryKeyType = {
  all: ["asset-registry"],
  lists: () => [...assetRegistryKeys.all, "lists"],
  list: (filters: any) => [...assetRegistryKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...assetRegistryKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [...assetRegistryKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...assetRegistryKeys.detail(id),
    params,
  ],
};

export const assetClassificationKeys: QueryKeyType = {
  all: ["asset-classification"],
  lists: () => [...assetClassificationKeys.all, "lists"],
  list: (filters: any) => [...assetClassificationKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...assetClassificationKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [
    ...assetClassificationKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: any) => [
    ...assetClassificationKeys.detail(id),
    params,
  ],
};

export const fixedAssetKeys: QueryKeyType = {
  all: ["fixed-assets"],
  lists: () => [...fixedAssetKeys.all, "lists"],
  list: (filters: any) => [...fixedAssetKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...fixedAssetKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [
    ...fixedAssetAdditionKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: any) => [
    ...fixedAssetKeys.detail(id),
    params,
  ],
};

export const productKeys: QueryKeyType = {
  all: ["products"],
  lists: () => [...productKeys.all, "lists"],
  list: (filters: any) => [...productKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [...productKeys.list(filters), "paginate", pageNumber, pageSize, sorter],
  detail: (id: number | string) => [...productKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...productKeys.detail(id),
    params,
  ],
};

export const contactVendorKeys: QueryKeyType = {
  all: ["contact-vendors"],
  lists: () => [...contactVendorKeys.all, "lists"],
  list: (filters: any) => [...contactVendorKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...contactVendorKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [...contactVendorKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...contactVendorKeys.detail(id),
    params,
  ],
};

export const tenantPaymentTermsKeys: QueryKeyType = {
  all: ["tenant-payment-terms"],
  lists: () => [...tenantPaymentTermsKeys.all, "lists"],
  list: (filters: any) => [...tenantPaymentTermsKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...tenantPaymentTermsKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [
    ...tenantPaymentTermsKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: any) => [
    ...tenantPaymentTermsKeys.detail(id),
    params,
  ],
};

export const inventoryKeys: QueryKeyType = {
  all: ["inventories"],
  lists: () => [...inventoryKeys.all, "lists"],
  list: (filters: any) => [...inventoryKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...inventoryKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [...inventoryKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...inventoryKeys.detail(id),
    params,
  ],
};

export const taxesKeys: QueryKeyType = {
  all: ["taxes"],
  lists: () => [...taxesKeys.all, "lists"],
  list: (filters: any) => [...taxesKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [...taxesKeys.list(filters), "paginate", pageNumber, pageSize, sorter],
  detail: (id: number | string) => [...taxesKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...taxesKeys.detail(id),
    params,
  ],
};

export const billKeys: QueryKeyType = {
  all: ["bills"],
  lists: () => [...billKeys.all, "lists"],
  list: (filters: any) => [...billKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [...billKeys.list(filters), "paginate", pageNumber, pageSize, sorter],
  detail: (id: number | string) => [...billKeys.all, "details", id],
  details: (id: number | string, params?: any) => [
    ...billKeys.detail(id),
    params,
  ],
};

export const commercialDocumentKeys: QueryKeyType = {
  all: ["commercial-documents"],
  lists: () => [...commercialDocumentKeys.all, "lists"],
  list: (filters: any) => [...commercialDocumentKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...commercialDocumentKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [
    ...commercialDocumentKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: any) => [
    ...commercialDocumentKeys.detail(id),
    params,
  ],
};

export const currentUserKeys: QueryKeyType = {
  all: ["current-user"],
};

export const depreciationKeys: QueryKeyType = {
  all: ["depreciations"],
  lists: () => [...depreciationKeys.all, "lists"],
  list: (filters: any) => [...depreciationKeys.lists(), filters],
  paginate: (
    filters: any,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...depreciationKeys.list(filters),
    "paginate",
    pageNumber,
    pageSize,
    sorter,
  ],
  detail: (id: number | string) => [...depreciationKeys.all, "details", id],
};
